import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgNeggin from "../img/neggin.jpg";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div id="primary" className="content-area">
      <main id="main" className="site-main" role="main">
        <article className="page type-page status-publish hentry not-found">
          <h1>NOT FOUND</h1>
          <div className="text-flow">
            <p>That page does not exist - go to the <a href="/">home page</a>.</p>
          </div>
        </article>
      </main>
    </div>

  </Layout>
)

export default NotFoundPage
